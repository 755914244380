import React from 'react';
import { Spinner } from 'baseui/spinner';
import axios from 'axios';
import { useStyletron } from 'baseui';
import { getServerUrl } from '../apollo/client';
import { Button } from 'baseui/button';
import LoginContainer from '../components/loginContainer';
import { Cell } from 'baseui/layout-grid';
import { adminSliders, userSliders } from '../helpers/slidersData';
import jwt_decode from "jwt-decode";
import { BUTTON_COLOR, TEXT_COLOR } from '../util/colorConstants';


const PasswordResetSuccess = () => {
  const [css, theme] = useStyletron();
  const urlParams = new URLSearchParams(window.location.search);
  const organization_id = urlParams.get('organization_id');
  const name = urlParams.get('name');
  const token = urlParams.get('token');
  const email_sent = urlParams.get('email_sent');
  const decoded = jwt_decode(token);
  const type = decoded.isAdmin ? 'admin' : 'user';
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const sliders = type === 'admin' ? adminSliders : userSliders;
  console.log(typeof email_sent);
  React.useEffect(() => {
    axios({
      url: `${getServerUrl()}/password-reset-success?organization_id=${organization_id}`,
      method: 'GET',
    }).then((responseJson) => {
      setOptions(responseJson.data.options);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      setError(err.message);
    })
  }, [organization_id]);

  return (
    <>
      <LoginContainer hasSliders={false} sliders={sliders} sloganSub={`${name}`} boxShadowSlider={false}>
        {isLoading ? <>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              color: theme.colors.primaryA,
            }}
          >
            Loading options
        </div>
          <div
            className={css({
              position: 'absolute',
              width: theme.sizing.scale1200,
              height: theme.sizing.scale1200,
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            })}
          >
            <Spinner />
          </div>
        </> :
          <>
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                color: TEXT_COLOR|| theme.colors.primaryA,
              }}
            >
              {/* {error ? error : `You are all set! ${type === 'admin' ? 'Now you can login into my admin.' : 'Based on your organization’s settings you can now either log in to the web portal or log in to the iOS or Android app installed on your device.'}`} */}
              {email_sent == 'true' && 'We have sent an email to reset the password after that, '}
              {error ? error : `You are all set! ${type === 'admin' ? 'Now you can login into my admin.' : 'Based on your organization’s settings you can now either log in to the web portal or download the iOS or Android app.'}`}
            </div>
            <Cell
              span={12}
              overrides={{
                Cell: {
                  style: () => ({
                    marginTop: '32px',
                  }),
                },
              }}
            >
              {type === 'admin' ?
                <Button
                  key={0}
                  overrides={{
                    BaseButton: { style: { width: '100%', marginTop: '16px', backgroundColor: BUTTON_COLOR || 'black' } },
                  }}
                  onClick={() => {
                    window.location.href = window.location.origin;
                  }}
                  disabled={error ? true : false}
                >
                  Goto My Admin
                </Button>
                : options.map((el, index) => {
                  return <Button
                    key={index}
                    overrides={{
                      BaseButton: { style: { width: '100%', marginTop: '16px' , backgroundColor: BUTTON_COLOR || 'black' } },
                    }}
                    onClick={() => {
                      window.location.href = el.url;
                    }}
                    disabled={error ? true : false}
                  >
                    {el.text}
                  </Button>
                })}
            </Cell>
          </>}
      </LoginContainer>
    </>
  );
};

export default PasswordResetSuccess;
